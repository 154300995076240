<template>
  <div class="wxlogin-container">
    <div v-if="ok">
      <i class="el-icon-success green"></i>
      <p class="text">授权成功，正在跳转...</p>
    </div>
    <div v-else>
      <i class="el-icon-error red"></i>
      <p class="text">登录失败，请重新登录！</p>
      <el-button type="default" @click="returnLogin">重新登录</el-button>
    </div>
  </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_url,setLocalItem} from '@/untils/common';
export default {
  data() {
    return {
      code: "",
      state: "",
      ok: false,
    };
  },
  created() {
    // 获取参数
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    // 判断是否有参数
    if (this.code&&this.state) {
      this.ok = true;
    } else {
      this.ok = false;
    }
    this.wxloginFn();
  },
  methods: {
    // 无论是ajax,post or get 还是 axios and jsonp ，控制台都会报一个 “跨域” 的Bug，查了许多资料，发现这个Bug是微信内部代码的问题，如何解决？
    // ①通过请求代理，将code发送给代理再获取access_token
    //  ②将code传输给后端接口，让后端获取用户信息。
    wxloginFn() {
      if (this.ok) {
        // https://api.weixin.qq.com/sns/oauth2/access_token?appid=wxfa34208611ccb881&secret=a71876b17e005c2fd77f3b22080a9923&code=011NZ2ll2qgHF94m9Wll2nEW9U1NZ2lF&grant_type=authorization_code
        // var target = 'https://api.weixin.qq.com/sns/oauth2/access_token?appid='+web_inter.wchart.client_id+'&secret='+web_inter.wchart.client_secret+'&code='+this.code+'&grant_type=authorization_code'
        // location.href=target
        this.$http.getWXToken({
          code: this.code
        }).then((res) => {
          if(res){
            if(res.code==1){
              if(res.data){
                if(res.data.ubid){
                  if(res.data.userinfo.length==0){
                    window.location.replace(yooz_url+'uinfo?type=2&ubid='+res.data.ubid)
                  }else if(res.data.userinfo&&res.data.userinfo.token){
                    setLocalItem('login_user_info',res.data.userinfo);
                    if(res.data.userinfo.family_name==null&&res.data.userinfo.token!=''){
                      window.location.replace(yooz_url+'uinfo?type=1');
                    }else{
                      location.href = yooz_url
                    }
                  }
                }
              }
            }else{
              ElementUI.Message({
                  message:res.message,
                  type:'error',
                  center: true,
                  offset:10,
              });
              return false;
            }
          }
        });
      }
    },
    // 返回登录页面
    returnLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.wxlogin-container {
  background-color: #fff;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.red {
  font-size: 72px;
  color: #f56c6c;
}
.green {
  font-size: 72px;
  color: #67c23a;
}
.text {
  font-size:16px;
  color: #303133;
}
</style>
